const OPEN_SOURCE = {
    mainTitle: 'Kubevious Open-Source',
    blocks: [
        {
            id: 1,
            title: 'Kubevious OSS',
            content: 'Kubevious OSS is the core of our technology. Runs and lives inside the cluster. Correlates and visualizes application configurations. Enforces best practices using rules engine. Allows time travel to root cause outage and misconfigurations.',
            imageUrl: '/img/contactPages/self-service.svg',
            link: 'https://github.com/kubevious/kubevious',
            btnText: 'Get from GitHub',
        },
        {
            id: 2,
            title: 'Kubevious Portable',
            content: 'A portable version of Kubevious which runs outside of the cluster, on your workstation in a stripped down docker container. Connects to remote Kubernetes cluster, provides quick visualization and configuration validation.',
            imageUrl: '/img/contactPages/self-service.svg',
            link: 'https://github.com/kubevious/portable',
            btnText: 'Get from GitHub',
        },
    ],
}

module.exports.OPEN_SOURCE = OPEN_SOURCE