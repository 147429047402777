import React from 'react'
import Layout from '../../components/Layout'
import * as styles from './styles.module.css'
import { OPEN_SOURCE } from '../../../content/data/openSourcePage'

import '../../styles/inner-pages.css'
import * as commonStyles from '../../styles/common-components.module.css'
import InnerLayout from '../../components/InnerLayout'

const OpenSource = () => {
    const pageData = OPEN_SOURCE
    return (
        <Layout
            title={pageData.mainTitle}
            forceFullLogo
            seoTitle="Kubevious :: OSS"
            seoDescription="Explore kubevious OSS"
        >
            <InnerLayout>
                <div className={styles.blocks}>
                    {pageData.blocks.map((block) => (
                        <div
                            className={styles.block}
                            id={block.id}
                            key={block.id}
                        >
                            <div className={styles.blockImage}>
                                <img src={block.imageUrl} alt="OSS" />
                            </div>

                            <div className={styles.blockContent}>
                                <div className={styles.blockTitle}>
                                    {block.title}
                                </div>
                                <div className={styles.blockDesc}>
                                    {block.content}
                                </div>
                            </div>

                            <a
                                className={`${commonStyles.linkButton} ${styles.button}`}
                                href={block.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {block.btnText}
                            </a>
                        </div>
                    ))}
                </div>
            </InnerLayout>
        </Layout>
    )
}

export default OpenSource
